import React from 'react';

function ContactUs() {
  return (
    <div>
      <h1>Contact Us</h1>
      <form>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" />
        </div>
        <div>
          <label htmlFor="subject">Subject:</label>
          <input type="text" id="subject" name="subject" />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message"></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      <div>
        <h2>Contact Information</h2>
        <p>Email: info@orbitsolutions.com</p>
        <p>Phone: (123) 456-7890</p>
        <p>Address: 123 Orbit St, Innovation City</p>
      </div>
    </div>
  );
}

export default ContactUs;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Home from './components/HomeComponents/Home';
import AboutUs from './components/AboutUsComponents/AboutUs';
import Products from './components/Products';
import ContactUs from './components/ContactUs';

function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Router>
      <div className="App">
        <header className={`App-header ${isMenuOpen ? "nav-open" : ""}`}>
          <img src="/logo512.svg" className="App-logo" alt="logo" />
          <button className="menu-toggle" onClick={toggleMenu}>
            ☰
          </button>
          <nav>
            <ul>
              <li>
                <Link to="/" onClick={closeMenu}>Home</Link>
              </li>
              <li>
                <Link to="/about-us" onClick={closeMenu}>About Us</Link>
              </li>
              <li>
                <Link to="/products" onClick={closeMenu}>Products</Link>
              </li>
              <li>
                <Link to="/contact-us" onClick={closeMenu}>Contact Us</Link>
              </li>
            </ul>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

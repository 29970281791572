import React from 'react';

function Products() {
  return (
    <div>
      <h1>Our Products</h1>
      <h2>Tracker Portal</h2>
      <p>Tracker Portal is a comprehensive SaaS platform designed to transform the way fitness clubs and personal trainers manage their clients and operations...</p>
      <h3>Client Portal</h3>
      <p>Allows clients to view their workout programs, track their progress, and receive notifications about their sessions and updates.</p>
      <h3>Trainer Portal</h3>
      <p>Enables trainers to create and manage workout programs, track client progress, schedule sessions, and update workout details.</p>
      <h3>Admin Portal</h3>
      <p>Provides tools for admins to monitor day-to-day activities of trainers, analyze performance metrics, and generate reports to assess overall efficiency and revenue generation.</p>
    </div>
  );
}

export default Products;

import React from 'react';
import './AboutUs.css';
import geronimoPortrait from './aboutUs_images/geronimo_portrait.jpg';
import josuePortrait from './aboutUs_images/josue_portrait.jpg';

function AboutUs() {
  // Company information content
  const missionContent = (
    <>
      <div className="h2-wrapper">
        <div className="orange-mark"></div>
        <h2>Our Mission</h2>
      </div>
      <p>At Orbit Solutions, our mission is to empower businesses by providing them with cutting-edge tools that streamline operations, improve productivity, and unlock new growth opportunities. We are dedicated to creating solutions that are not only powerful but also user-friendly and accessible, ensuring that every business can benefit from our innovations.</p>
    </>
  );

  // Vision section content
  const visionContent = (
    <>
      <div className="h2-wrapper">
        <div className="orange-mark"></div>
        <h2>Our Vision</h2>
      </div>
      <p>We envision a future where businesses of all sizes can leverage technology to achieve their full potential. Our goal is to lead the industry in innovation and customer-centric solutions, building platforms that make a real difference. By prioritizing security, scalability, and reliability, we aim to be the go-to provider for businesses looking to enhance their efficiency and drive growth.</p>
    </>
  );

  // Values section content
  const valuesContent = (
    <>
      <div className="h2-wrapper">
        <div className="orange-mark"></div>
        <h2>Our Values</h2>
      </div>
      <p>We are committed to:</p>
      <ul>
        <li><strong>Innovation:</strong> Continuously pushing the boundaries of technology to deliver state-of-the-art solutions.</li>
        <li><strong>Customer-Centricity:</strong> Focusing on the needs of our customers and tailoring our products to meet their unique requirements.</li>
        <li><strong>Excellence:</strong> Striving for the highest standards in everything we do, from product development to customer service.</li>
        <li><strong>Integrity:</strong> Conducting our business with honesty and transparency, building trust with our clients and partners.</li>
        <li><strong>Collaboration:</strong> Working together with our clients, partners, and within our team to achieve common goals and drive success.</li>
      </ul>
    </>
  );

  // Products section content
  const productsContent = (
    <>
      <div className="h2-wrapper">
        <div className="orange-mark"></div>
        <h2>Our Products</h2>
      </div>
      <p>Our flagship product, Tracker Portal, is a comprehensive SaaS platform designed to transform the way fitness clubs and personal trainers manage their clients and operations. Tracker Portal offers distinct portals for clients, trainers, and admins, each tailored to their specific needs.</p>
      <p>With features like personalized workout programs, progress tracking, notifications, and comprehensive administrative tools, Tracker Portal enhances client retention, increases revenue, and streamlines operations.</p>
    </>
  );

  return (
    <div className="AboutUs">
      {/* Mission Section */}
      <div className="Section Mission-section">
        <div className="Section-content">
          <div className="Section-column text">
            {missionContent}
          </div>
        </div>
      </div>

      <div className="separator"></div>

      {/* Vision Section */}
      <div className="Section Vision-section">
        <div className="Section-content">
          <div className="Section-column text">
            {visionContent}
          </div>
        </div>
      </div>

      <div className="separator"></div>

      {/* Values Section */}
      <div className="Section Values-section">
        <div className="Section-content">
          <div className="Section-column text">
            {valuesContent}
          </div>
        </div>
      </div>

      <div className="separator"></div>

      {/* Products Section */}
      <div className="Section Products-section">
        <div className="Section-content">
          <div className="Section-column text">
            {productsContent}
          </div>
        </div>
      </div>

      <div className="separator"></div>

      {/* About Geronimo Basurto Section */}
      <div className="Section AboutUs-section">
        <div className="Section-content">
          <div className="Section-column image">
            <img src={geronimoPortrait} alt="Geronimo Basurto" className="Section-image" />
          </div>
          <div className="Section-column text">
            <div className="h2-wrapper">
              <div className="orange-mark"></div>
              <h2>About Geronimo Basurto</h2>
            </div>
            <p>Geronimo Basurto, Founder & Chief Developer at Orbit Solutions, brings a wealth of expertise in software development and infrastructure design. He is the mastermind behind Tracker Portal, a comprehensive SaaS platform designed to enhance the efficiency and effectiveness of fitness clubs and personal trainers.</p>
            <p>With a strong background in both fitness management and software development, Geronimo has seamlessly integrated his skills to create innovative solutions that streamline operations and drive business growth.</p>
          </div>
        </div>
      </div>

      <div className="separator"></div>

      {/* About Josue Garcilazo Section */}
      <div className="Section AboutUs-section">
        <div className="Section-content">
          <div className="Section-column image">
            <img src={josuePortrait} alt="Josue Garcilazo" className="Section-image" />
          </div>
          <div className="Section-column text">
            <div className="h2-wrapper">
              <div className="orange-mark"></div>
              <h2>About Josue Garcilazo</h2>
            </div>
            <p>Josue Garcilazo, Co-founder & Chief Strategy Officer at Orbit Solutions, is the driving force behind the company's market research and customer acquisition efforts. His keen insights and strategies have been instrumental in introducing Tracker Portal to businesses, helping them achieve their goals through tailored solutions.</p>
            <p>With a background in business and a passion for innovation, Josue's strategic approach ensures that Orbit Solutions stays ahead of market trends and continues to deliver exceptional value to its clients.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;

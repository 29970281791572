import React, { useState, useEffect } from 'react';
import './Home.css';
import Typewriter from 'typewriter-effect';
import heroImage from './home_images/hero_image.jpg';
import aboutSectionImage from './home_images/about_section.jpg';
import productSectionImage from './home_images/product_section_image.jpg';
import ctaImage from './home_images/cta_image.jpg';
import expertiseImage from './home_images/expertise_image.jpg';

function Home() {
  // Hook to get and update window size
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener('resize', handleResize);

      handleResize(); // Call the function initially to set the size

      return () => window.removeEventListener('resize', handleResize); // Clean up event listener on component unmount
    }, []);

    return windowSize;
  }

  // Get current window size
  const size = useWindowSize();
  const isMobile = size.width <= 768; // Determine if the current view is mobile based on width

  // Style for hero section
  const heroStyle = {
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    textAlign: 'center',
    padding: '100px 20px',
  };

  // About section content
  const aboutContent = (
    <>
      <div className="h2-wrapper">
        <div className="orange-mark"></div>
        <h2>About Orbit Solutions</h2>
      </div>
      <p>We are a pioneering technology company dedicated to building innovative SaaS platforms designed to enhance business efficiency and drive revenue growth.</p>
      <p>Our mission is to leverage technology to streamline operations and unlock new growth opportunities across various industries.</p>
      <p>Founded by Geronimo Basurto and Josue Garcilazo, our team combines technical expertise and market research skills to create impactful software solutions.</p>
    </>
  );

  // Expertise section content
  const expertiseContent = (
    <>
      <div className="h2-wrapper">
        <div className="orange-mark"></div>
        <h2>Our Expertise</h2>
      </div>
      <p>We provide innovative solutions across industries with a strong foundation in technology. Specializing in scalable SaaS platforms, we streamline operations and enhance productivity. Our expertise includes reliable data management, efficient server operations, and dynamic user interfaces.</p>
      <p>
        <ul>
          <li><strong>Data Management and Analytics:</strong> We ensure efficient and reliable data storage, enabling businesses to make data-driven decisions with ease.</li>
          <li><strong>Efficient Server Operations:</strong> We create fast and scalable server-side applications that handle high volumes of transactions and user interactions smoothly.</li>
          <li><strong>Dynamic User Interfaces:</strong> We build intuitive and responsive user interfaces that enhance user experience and engagement.</li>
          <li><strong>Mobile Integration:</strong> Our solutions are designed to be accessible on mobile devices, ensuring that businesses can operate seamlessly on-the-go.</li>
          <li><strong>Security:</strong> We prioritize the security and integrity of user data, protecting businesses from potential threats.</li>
          <li><strong>Scalability:</strong> Our architecture supports the growth of your business, allowing you to expand and adapt without compromising performance or reliability.</li>
        </ul>
      </p>
      <p>We are committed to leveraging technology to streamline operations, boost productivity, and drive growth across various business sectors.</p>
    </>
  );

  // Product section content
  const productContent = (
    <>
      <div className="h2-wrapper">
        <div className="orange-mark"></div>
        <h2>Our Flagship Product: Tracker Portal</h2>
      </div>
      <p>Tracker Portal is a comprehensive SaaS platform designed to transform the way fitness clubs and personal trainers manage their clients and operations.</p>
    </>
  );

  return (
    <div>
      {/* Hero Section */}
      <div style={heroStyle} className="Home-hero">
        <h1 className="animated fadeIn Home-heading">Welcome to Orbit&nbsp;Solutions</h1>
        <div className="Hero-content">
          <div className="Typewriter-container animated fadeIn">
            <h3>
              Your Gateway to
              <span className="Typewriter">
                <Typewriter
                  options={{
                    strings: [
                      ' Superior Business Solutions',
                      ' Innovation and Efficiency',
                      ' Ultimate Productivity',
                      ' Next-Level Growth'
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 50,
                  }}
                />
              </span>
            </h3>
          </div>
          <p className="animated fadeIn delay-1s">Discover innovative tools designed to optimize operations, boost productivity, and drive growth.</p>
          <a href="/products" className="cta-button animated fadeIn delay-2s">Explore Our Products</a>
        </div>
      </div>

      {/* About Section */}
      <div className={`Section About-section ${isMobile ? '' : 'alternate'}`}>
        <div className="Section-content">
          {isMobile ? (
            <>
              <div className="Section-column image">
                <img src={aboutSectionImage} alt="About Us" className="Section-image" />
              </div>
              <div className="Section-column text">
                {aboutContent}
              </div>
            </>
          ) : (
            <div className="Section-column text">
              <div className="Section-image-wrapper">
                <img src={aboutSectionImage} alt="About Us" className="Section-image" />
              </div>
              {aboutContent}
            </div>
          )}
        </div>
      </div>

      <div className="separator"></div>

      {/* Expertise Section */}
      <div className={`Section Expertise-section ${isMobile ? '' : 'alternate'}`}>
        <div className="Section-content">
          {isMobile ? (
            <>
              <div className="Section-column image">
                <img src={expertiseImage} alt="Expertise" className="Section-image" />
              </div>
              <div className="Section-column text">
                {expertiseContent}
              </div>
            </>
          ) : (
            <div className="Section-column text">
              <div className="Section-image-wrapper">
                <img src={expertiseImage} alt="Expertise" className="Section-image" />
              </div>
              {expertiseContent}
            </div>
          )}
        </div>
      </div>

      <div className="separator"></div>

      {/* Product Section */}
      <div className={`Section Product-section ${isMobile ? '' : 'alternate'}`}>
        <div className="Section-content">
          {isMobile ? (
            <>
              <div className="Section-column image">
                <img src={productSectionImage} alt="Product" className="Section-image" />
              </div>
              <div className="Section-column text">
                {productContent}
              </div>
            </>
          ) : (
            <div className="Section-column text">
              <div className="Section-image-wrapper">
                <img src={productSectionImage} alt="Product" className="Section-image" />
              </div>
              {productContent}
            </div>
          )}
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="Section CTA-section" style={{ backgroundImage: `url(${ctaImage})` }}>
        <div className="CTASection-content">
          <h3>Join Our Journey</h3>
          <p>Stay updated with the latest news and be the first to know about our new projects and developments.</p>
          <a href="/contact-us" className="cta-button">Contact Us</a>
        </div>
      </div>
    </div>
  );
}

export default Home;
